import { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import '../../styles/Label.css'
import { useReactToPrint } from 'react-to-print'
import iconLavender from '../img/lavender.png';

interface PurchaseBook {
    id: string
    cep: string
    name: string
    address: string
    number: string
    complement:string
    neighborhood: string
    city: string
    email: string
    state: string
    pixComprovante: string
    createdAt: string
  }

interface IProps{
    showModal:boolean
    handleGenerateClick:any
    addressList: PurchaseBook[]
}

const Labels = (props:IProps) => {

    const {addressList} = props  
    const { showModal, handleGenerateClick} = props
    const contentRef = useRef<HTMLDivElement>(null)


    const handlePrint = useReactToPrint({
        content: () => contentRef.current
      })

      const formatCep = (cep: string): string => {
        const cleanedCep = cep.replace(/\D/g, '')

        if (cleanedCep.length === 8) {
          return cleanedCep.replace(/(\d{5})(\d{3})/, '$1-$2')
        }
      
        return cleanedCep
      }

      return (
        <>
          <Modal show={showModal} fullscreen={true} onHide={() => handleGenerateClick(false)}>
            <Modal.Header closeButton>
              <button onClick={() => handlePrint()}>Gerar</button>
            </Modal.Header>
            <Modal.Body>
              <div className="sheet" ref={contentRef}>
                {
                  addressList.slice(1).map((item: PurchaseBook, index: number) => {

                    const row = index + 1
            
                    return (
                      <div
                        className="label-container"
                        style={row % 8 === 0? { marginBottom: '250px' } : {}}
                        key={index}
                      >
                        <div className="box-recipient">
                          <div className="recipient-title"><img src={iconLavender} className="img-fluid" style={{ maxWidth: '25px' }} /> DESTINATÁRIO</div>
                          <p>{item.name}</p>
                          <p>{item.address} {item.number} {item.complement.toLowerCase().includes('não possu') ? '' : item.complement.toLowerCase()}</p>
                          <p>{item.neighborhood.includes('@') ? 'Vila Satúrnia' : item.neighborhood }</p>
                          <p><b>{formatCep(item.cep)}</b> {item.city}-{item.state}</p>
                        </div>
    
                        <div className="box-sender">
                          <p><b>Remetente:</b> Laura Melo</p>
                          <p>Rua Dulce Custódio Apolinário 67, Apt 302</p>
                          <p>Residencial Morumbi</p>
                          <p>37716-321 Poços de Caldas-MG</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </Modal.Body>
          </Modal>
        </>
      )
      

}
export default Labels