import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateLabel from './GenerateLabel';

interface PurchaseBook {
  id: string;
  cep: string;
  name: string;
  address: string;
  number: string;
  complement:string
  neighborhood: string;
  city: string;
  email: string;
  state: string;
  pixComprovante: string;
  createdAt: string;
}

interface ResponseData {
  message: PurchaseBook[];
}

const ListPurchaseBook: React.FC = () => {
  const [data, setData] = useState<PurchaseBook[]>([]);
  const [filteredData, setFilteredData] = useState<PurchaseBook[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ResponseData>('https://nevoambar.com.br/api/purchasebook', {});
        setData(response.data.message);
        setFilteredData(response.data.message);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter(item =>
        Object.values(item).some(value =>
          value.toString().toLowerCase().includes(filter.toLowerCase())
        )
      )
    );
  }, [filter, data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container mt-5">
      <h1>Compras: {filteredData.length}</h1>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Filter..."
        value={filter}
        onChange={e => setFilter(e.target.value)}
      />
      <GenerateLabel
      addressList = {filteredData}
      />

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>---</th>
            <th>CEP</th>
            <th>Name</th>
            <th>Rua</th>
            <th>Numero</th>
            <th>Bairro</th>
            <th>Cidade</th>
            <th>Estado</th>
            <th>Email</th>
            <th>Pix Comprovante</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.cep}</td>
              <td>{item.name}</td>
              <td>{item.address}</td>
              <td>{item.number}</td>
              <td>{item.neighborhood}</td>
              <td>{item.city}</td>
              <td>{item.state}</td>
              <td>{item.email}</td>
              <td>
                <a
                  href={`https://purchasebookcompropix-bucket.s3.us-east-2.amazonaws.com/${item.pixComprovante}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`https://purchasebookcompropix-bucket.s3.us-east-2.amazonaws.com/${item.pixComprovante}`}
                    alt="Pix Comprovante"
                    style={{ width: '100px', height: 'auto' }}
                  />
                </a>
              </td>
              <td>{new Date(item.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListPurchaseBook;
