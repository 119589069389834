import "bootstrap/dist/css/bootstrap.min.css";
import './FormBay'
import FormBay from "./FormBay";

const Home = () => {

  return (
    <FormBay />
  )
}

export default Home