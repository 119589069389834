import Modal from 'react-modal'
//import ErrorIcon from '../Images/error-icon-animated.gif'
import '../../styles/Button.css'

interface IErrorModalProps {
  showModal: boolean
  closeModal: () => void,
  title: string,
  text: string
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const DefaultModal = (props: IErrorModalProps) => {
  const { showModal, closeModal, title, text } = props
  return (
    <Modal
      isOpen={showModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="row justify-content-center mb-1">
            <div className="col-auto fs-3">
              <div className='row'>
                <div className='col d-flex justify-content-center'>
                  <h5>{title}</h5>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mb-3">
              <div className="col fs-6 text-center text-secondary">{text}</div>
            </div>

            <div className="row justify-content-center my-2">
              <div className="col-auto">
                <button type="button" className="custom-btn custom-btn-primary" onClick={closeModal}>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DefaultModal
