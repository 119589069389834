import React, { useState } from 'react'
import '../styles/Form.css'
import '../styles/Button.css'
import Modal from 'react-modal'
import ComplementEmptyModal from './Modals/ComplementEmptyModal'
import axios from 'axios'
import { ReactComponent as InstagramIcon } from './svg/icon-instagram.svg';
import DefaultModal from './Modals/DefaultModal'
import LoadingModal from './Modals/LoadingModal'
import iconLavender from './img/lavender.png';

const FormBay = () => {
    const [cep, setCep] = useState('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [number, setNumber] = useState('')
    const [complement, setComplement] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [email, setEmail] = useState('')
    const [pixComprovante, setPixComprovante] = useState<File | null>(null)
    const [showModalComplementEmpty, setShowModalComplementEmpty] = useState<boolean>(false)
    const [noComplementAccept, setNoComplementAccept] = useState<boolean>(false)
    const [showDefaultModal, setShowDefaultModal] = useState<boolean>(false)
    const [titleDefaultModal, setTitleDefaultModal] = useState<string>('')
    const [textDefaultModal, setTextDefaultModal] = useState<string>('')
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [textLoadingModal, setTextLoadingModal] = useState<string>('')
    const [sendWithSuccess, setSendWithSuccess] = useState<boolean>(false)


    const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setCep(value)
        viaCep(value)
    }

    const viaCep = (cep: string) => {
        if (cep.length === 8) {
            setShowLoadingModal(true)
            setTextLoadingModal("Buscando seus dados...")
            const currentCEP = cep.replace(/\D/g, '')
            fetch(`https://viacep.com.br/ws/${currentCEP}/json/?callbac`)
                .then(response => response.json())
                .then(data => {
                    if (!data.erro) {
                        setAddress(data.logradouro)
                        setNeighborhood(data.bairro)
                        setCity(data.localidade)
                        setState(data.uf)
                    } else {
                        console.log('CEP não encontrado')
                    }

                    setShowLoadingModal(false)
                    setTextLoadingModal("")
                })
                .catch(error => {
                    console.error('Erro ao buscar CEP:', error)
                    setShowLoadingModal(false)
                    setTextLoadingModal("")
                })
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)
    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => setNumber(e.target.value)
    const handleComplementChange = (e: React.ChangeEvent<HTMLInputElement>) => setComplement(e.target.value)
    const handleNeighborhoodChange = (e: React.ChangeEvent<HTMLInputElement>) => setNeighborhood(e.target.value)
    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)
    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => setState(e.target.value)
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)

    const handlePixComprovanteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setPixComprovante(e.target.files[0])
        }
    }

    const validEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        return regex.test(email)
    }

    const handleSubmit = async () => {
        const fields = [
            { value: cep, name: "CEP" },
            { value: name, name: "Nome" },
            { value: address, name: "Endereço" },
            { value: number, name: "Numero" },
            { value: neighborhood, name: "Bairro" },
            { value: city, name: "Cidade" },
            { value: state, name: "Estado" },
            { value: email, name: "Email" },
            { value: pixComprovante, name: "Comprovante de PIX" }
        ]

        for (let field of fields) {
            if (!field.value) {
                setShowDefaultModal(true)
                setTitleDefaultModal('Ups ...')
                setTextDefaultModal(`Por favor preencha o campo ${field.name}`)
                return
            }
        }

        if (!complement && !noComplementAccept) {
            setShowModalComplementEmpty(true)
            return
        }

        const formData = new FormData()
        formData.append('cep', cep)
        formData.append('name', name)
        formData.append('address', address)
        formData.append('number', number)
        formData.append('neighborhood', neighborhood)
        formData.append('city', city)
        formData.append('state', state)
        formData.append('email', email)
        formData.append('pixComprovante', pixComprovante as File)
        formData.append('complement', complement)

        if (!validEmail(email)) {
            setShowDefaultModal(true)
            setTitleDefaultModal('Ups ...')
            setTextDefaultModal(`Por favor, preencha um email válido.`)
            return
        }

        try {

            setShowLoadingModal(true)
            setTextLoadingModal("Enviando seus dados...")

            await axios.post('https://nevoambar.com.br/api/submit', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            setNoComplementAccept(false)
            setShowLoadingModal(false)
            setTextLoadingModal("")
            setSendWithSuccess(true)

        } catch (error) {

            setNoComplementAccept(false)
            setShowDefaultModal(true)
            setTitleDefaultModal('Erro')
            setTextDefaultModal('Não foi possivel enviar os dados.')
            setShowLoadingModal(false)
            setTextLoadingModal("")
        }
    }

    return (
        <>
            {!sendWithSuccess && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="card shadow-sm d-flex m-4" style={{ width: '80%' }}>
                        <div className="row justify-content-center my-4">
                            <div className="col-md-8 order-md-1">
                                <div className="text-center mb-4">
                                    <img src={iconLavender} className="img-fluid" style={{ maxWidth: '75px' }} />
                                </div>
                                <h1 className="text-center mb-4">Pré-venda Névoambar</h1>
                                <div className="book-details text-center mb-4">
                                    <p>
                                        Você está super convidado para o grande festival de outono de Névoambar!
                                        Mas antes, algumas informações importantes! Por favor, leia atentamente:
                                    </p>

                                    <p><b>1-</b> O valor do livro físico é de R$ 60,00 (com frete incluso) MEU PIX: LAURAPEREIRAMELO@GMAIL.COM</p>
                                    <p><b>2-</b> Por favor, preencha os campos do endereço com calma e corretamente para que não aconteça nenhum erro nos envios</p>
                                    <p><b>3-</b> Esse ano temos uma novidade, o conto "O parque itinerante" está incluso no final do livro</p>
                                    <p><b>4-</b> Vou manter todos informados sobre os envios nos stories, então fique de olho no instagram (@lau.pmelo)</p>


                                    <p>
                                        MUITO OBRIGADA do fundo do meu coração por apoiar a minha história! Espero que se aconchegue, investigue e se divirta muito durante a sua estadia em nosso rochedo.
                                    </p>

                                    <p>
                                        Obrigada por apoiar uma escritora nacional independente s2
                                        Agora vamos conhecer um pouquinho da história?
                                    </p>

                                    <p className=''>
                                        O pequeno rochedo de Névoambar, constantemente coberto por uma espessa névoa, cercado por lagos congelantes e por segredos sombrios, é muito conhecido pelas festividades de Halloween que ocorrem durante todo o mês de Outubro. Entretanto, os maiores atrativos aos visitantes são a temida lenda da névoa púrpura, os diversos relatos de aparições e os assassinatos não solucionados.
                                        Mágory Lavanda trabalhou a vida inteira no armazém da família, porém, nesse Halloween, ela conseguiu a chance de trabalhar no grande Hotel Névoambar, e as respostas que ela encontrará por lá podem não ser as mais festivas.
                                    </p>
                                </div>
                                <form className="needs-validation" noValidate>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="CEP">CEP</label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                id="CEP"
                                                placeholder="Digite o seu CEP"
                                                value={cep}
                                                onChange={handleCepChange}
                                                required
                                            />
                                            <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" className="form-text">Não sabe o CEP?</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="name">Nome</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Digite o nome"
                                                value={name}
                                                onChange={handleNameChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 mb-3">
                                            <label htmlFor="address">Rua</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                placeholder="Digite o endereço"
                                                value={address}
                                                onChange={handleAddressChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="number">Número</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="number"
                                                placeholder="Digite o número"
                                                value={number}
                                                onChange={handleNumberChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="complement">Complemento</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="complement"
                                                placeholder="Digite o complemento"
                                                value={complement}
                                                onChange={handleComplementChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="neighborhood">Bairro</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="neighborhood"
                                                placeholder="Digite o bairro"
                                                value={neighborhood}
                                                onChange={handleNeighborhoodChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="city">Cidade</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="form-control"
                                                id="city"
                                                placeholder="Digite a cidade"
                                                value={city}
                                                onChange={handleCityChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="state">Estado</label>
                                            <select
                                                autoComplete="off"
                                                id="state"
                                                className="form-control"
                                                value={state}
                                                onChange={handleStateChange}
                                                required
                                            >
                                                <option value="">Escolha...</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="PR">Paraná</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="BA">Bahia</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="CE">Ceará</option>
                                                <option value="PA">Pará</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="TO">Tocantins</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="RR">Roraima</option>
                                                <option value="AP">Amapá</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="PI">Piauí</option>
                                                <option value="DF">Distrito Federal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                autoComplete="off"
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Digite o seu email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="pixComprovante">Comprovante PIX (MEU PIX: LAURAPEREIRAMELO@GMAIL.COM)</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="pixComprovante"
                                                onChange={handlePixComprovanteChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <button className="custom-btn custom-btn-primary col-12" onClick={handleSubmit} type="button">Comprar Agora</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            )}

            {sendWithSuccess && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="card shadow-sm d-flex m-4" style={{ width: '60%' }}>
                        <div className="row justify-content-center my-4">
                            <div className="col-md-12 order-md-1">
                                <div className="text-center mb-4">
                                    <p>Obrigada!</p>
                                    <p>O email de confirmação da sua compra foi enviado para o endereço de email que você preencheu no formulário. Caso não encontre, por favor, verifique a caixa de spam. De todo modo, se você está lendo esta mensagem, sua compra está confirmada.</p>
                                    <p>Acompanhe tudo pelo Instagram: @lau.pmelo</p>
                                    <p>Nos vemos em Névoambar 💜</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ComplementEmptyModal
                showModal={showModalComplementEmpty}
                handleSubmit={() => {
                    setNoComplementAccept(true)
                    setShowModalComplementEmpty(false)
                    setTitleDefaultModal('')
                    setTextDefaultModal('')
                    handleSubmit()
                }}
                cancelHandleSubmit={() => {
                    setNoComplementAccept(false)
                    setShowModalComplementEmpty(false)
                    setTitleDefaultModal('');
                    setTextDefaultModal('')
                }}
            />

            <DefaultModal
                showModal={showDefaultModal}
                closeModal={() => {
                    setShowDefaultModal(false)
                    setTitleDefaultModal('')
                    setTextDefaultModal('')
                }}
                title={titleDefaultModal}
                text={textDefaultModal}
            />

            <LoadingModal
                showModal={showLoadingModal}
                text={textLoadingModal}
            />
        </>
    )
}

export default FormBay
