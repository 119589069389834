import { useState } from 'react'
import '../styles/Form.css'
import '../styles/Button.css'
import '../styles/Label.css'
import './Modals/Labels'
import Labels from './Modals/Labels'

interface PurchaseBook {
    id: string;
    cep: string;
    name: string;
    address: string;
    number: string;
    complement: string
    neighborhood: string;
    city: string;
    email: string;
    state: string;
    pixComprovante: string;
    createdAt: string;
  }
  

interface IProps {
    addressList: PurchaseBook[]
}


const GenerateLabel = (props: IProps) => {
  const {addressList} = props  
  const [showModal, setShowModal] = useState(false)

  const handleGenerateClick = (open:boolean) => {
    setShowModal(open)
  }

  return (
    <div>
      <div className="row">
        <div className="col d-flex justify-content-end">
          <button
            className="custom-btn custom-btn-primary"
            onClick={() => handleGenerateClick(true)}
            type="button"
          >
            Gerar Etiqueta
          </button>
        </div>
      </div>
        <Labels
        showModal = {showModal}
        handleGenerateClick = {handleGenerateClick}
        addressList = {addressList}
        />
    </div>
  )
}

export default GenerateLabel
