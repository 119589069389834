import Modal from 'react-modal'
//import ErrorIcon from '../Images/error-icon-animated.gif'
import '../../styles/Button.css'

interface IErrorModalProps {
  showModal: boolean
  handleSubmit: () => void
  cancelHandleSubmit: () => void
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ComplementEmptyModal = (props: IErrorModalProps) => {
  const { showModal, handleSubmit, cancelHandleSubmit } = props
  return (
    <Modal
      isOpen={showModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content py-4 px-5">
          <div className="row justify-content-center mb-1">
            <div className="col-auto fs-3">
              <div className='row'>
                <div className='col d-flex justify-content-center'>
                  <h5>Ups...</h5>
                </div>
              </div>
            </div>

            <div className="row justify-content-center mb-3">
              <div className="col fs-6 text-center text-secondary">O campo complemento não foi preenchido</div>
            </div>

            <div className="row justify-content-center">
              <div className="col-auto">
                <button type="button" className="custom-btn custom-btn-primary" onClick={handleSubmit}>
                  Não possuo complemento
                </button>
              </div>
              <div className="col-auto">
                <a className="custom-btn custom-btn-primary" onClick={cancelHandleSubmit}>
                  Preencher
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ComplementEmptyModal
